<template>
  <div class="MflSecurity">
    <!-- 抬头标题 -->
    <div class="title-hang">
      <div class="fl-t">
        <dv-decoration-1 style="width: 10vw; height: 3vh; margin-top: 1vh" />
      </div>
      <!-- <div class="zhuangshizb fl-t"></div> -->
      <div class="zhuangshizj fl-t">{{ projectData.project }}_运行考核</div>
      <div class="zhuangshiyb fl-t"></div>
      <div class="fr-t">
        <div class="fl-t">
          <dv-decoration-1 style="width: 10vw; height: 3vh; margin-top: 1vh" />
        </div>
      </div>
    </div>
    <!-- 第一个表格 -->

    <div class="sjxs fl-t">
      <div class="row">数据显示</div>
      <div class="throw flex">
        <div class="c1 fl-t">序号</div>
        <div class="c2 fl-t">班运行</div>
        <div class="c3 fl-t">天运行</div>
        <div class="c4 fl-t">月运行</div>
        <div class="c5">自定义运行</div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">给水累计(T)</div>
        <div
          class="c2 fl-t"
          @click="
            toCompon(
              2,
              'KH_GSLJB',
              infoList.KH_GSLJB_node_string,
              'KH_GSLJB_BM',
              '给水班累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GSLJB,
              'KH_GSLJB',
              infoList.KH_GSLJB_node_string,
              'KH_GSLJB_BM'
            )
          "
        >
          {{ "KH_GSLJB" in infoList ? infoList.KH_GSLJB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="
            toCompon(
              2,
              'KH_GSLJT',
              infoList.KH_GSLJT_node_string,
              'KH_GSLJT_BM',
              '给水天累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GSLJT,
              'KH_GSLJT',
              infoList.KH_GSLJT_node_string,
              'KH_GSLJT_BM'
            )
          "
        >
          {{ "KH_GSLJT" in infoList ? infoList.KH_GSLJT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="
            toCompon(
              2,
              'KH_GSLJY',
              infoList.KH_GSLJY_node_string,
              'KH_GSLJY_BM',
              '给水月累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GSLJY,
              'KH_GSLJY',
              infoList.KH_GSLJY_node_string,
              'KH_GSLJY_BM'
            )
          "
        >
          {{ "KH_GSLJY" in infoList ? infoList.KH_GSLJY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(
              2,
              'KH_GSLJS',
              infoList.KH_GSLJS_node_string,
              'KH_GSLJS_BM',
              '给水自定义考核累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GSLJS,
              'KH_GSLJS',
              infoList.KH_GSLJS_node_string,
              'KH_GSLJS_BM'
            )
          "
        >
          {{ "KH_GSLJS" in infoList ? infoList.KH_GSLJS : 0 }}
        </div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">蒸汽累计(T)</div>
        <div
          class="c2 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZQLJB',
              infoList.KH_ZQLJB_node_string,
              'KH_ZQLJB_BM',
              '蒸汽班累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZQLJB,
              'KH_ZQLJB',
              infoList.KH_ZQLJB_node_string,
              'KH_ZQLJB_BM'
            )
          "
        >
          {{ "KH_ZQLJB" in infoList ? infoList.KH_ZQLJB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZQLJT',
              infoList.KH_ZQLJT_node_string,
              'KH_ZQLJT_BM',
              '蒸汽天累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZQLJT,
              'KH_ZQLJT',
              infoList.KH_ZQLJT_node_string,
              'KH_ZQLJT_BM'
            )
          "
        >
          {{ "KH_ZQLJT" in infoList ? infoList.KH_ZQLJT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZQLJY',
              infoList.KH_ZQLJY_node_string,
              'KH_ZQLJY_BM',
              '蒸汽月累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZQLJY,
              'KH_ZQLJY',
              infoList.KH_ZQLJY_node_string,
              'KH_ZQLJY_BM'
            )
          "
        >
          {{ "KH_ZQLJY" in infoList ? infoList.KH_ZQLJY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZQLJS',
              infoList.KH_ZQLJS_node_string,
              'KH_ZQLJS_BM',
              '蒸汽自定义考核累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZQLJS,
              'KH_ZQLJS',
              infoList.KH_ZQLJS_node_string,
              'KH_ZQLJS_BM'
            )
          "
        >
          {{ "KH_ZQLJS" in infoList ? infoList.KH_ZQLJS : 0 }}
        </div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">煤量(Wm³)</div>
        <div
          class="c2 fl-t"
          @click="
            toCompon(
              2,
              'KH_GLLJB',
              infoList.KH_GLLJB_node_string,
              'KH_GLLJB_BM',
              '给煤班累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GLLJB,
              'KH_GLLJB',
              infoList.KH_GLLJB_node_string,
              'KH_GLLJB_BM'
            )
          "
        >
          {{ "KH_GLLJB" in infoList ? infoList.KH_GLLJB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="
            toCompon(
              2,
              'KH_GLLJT',
              infoList.KH_GLLJT_node_string,
              'KH_GLLJT_BM',
              '给煤天累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GLLJT,
              'KH_GLLJT',
              infoList.KH_GLLJT_node_string,
              'KH_GLLJT_BM'
            )
          "
        >
          {{ "KH_GLLJT" in infoList ? infoList.KH_GLLJT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="
            toCompon(
              2,
              'KH_GLLJY',
              infoList.KH_GLLJY_node_string,
              'KH_GLLJY_BM',
              '给煤月累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GLLJY,
              'KH_GLLJY',
              infoList.KH_GLLJY_node_string,
              'KH_GLLJY_BM'
            )
          "
        >
          {{ "KH_GLLJY" in infoList ? infoList.KH_GLLJY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(
              2,
              'KH_GLLJS',
              infoList.KH_GLLJS_node_string,
              'KH_GLLJS_BM',
              '给煤自定义考核累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_GLLJS,
              'KH_GLLJS',
              infoList.KH_GLLJS_node_string,
              'KH_GLLJS_BM'
            )
          "
        >
          {{ "KH_GLLJS" in infoList ? infoList.KH_GLLJS : 0 }}
        </div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">吨汽耗(煤)</div>
        <div
          class="c2 fl-t"
          @click="
            toCompon(
              2,
              'KH_MQHB',
              infoList.KH_MQHB_node_string,
              'KH_MQHB_BM',
              '班吨蒸汽煤耗'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_MQHB,
              'KH_MQHB',
              infoList.KH_MQHB_node_string,
              'KH_MQHB_BM'
            )
          "
        >
          {{ "KH_MQHB" in infoList ? infoList.KH_MQHB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="
            toCompon(
              2,
              'KH_MQHT',
              infoList.KH_MQHT_node_string,
              'KH_MQHT_BM',
              '天吨汽煤耗'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_MQHT,
              'KH_MQHT',
              infoList.KH_MQHT_node_string,
              'KH_MQHT_BM'
            )
          "
        >
          {{ "KH_MQHT" in infoList ? infoList.KH_MQHT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="
            toCompon(
              2,
              'KH_MQHY',
              infoList.KH_MQHY_node_string,
              'KH_MQHY_BM',
              '月吨蒸汽煤耗'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_MQHY,
              'KH_MQHY',
              infoList.KH_MQHY_node_string,
              'KH_MQHY_BM'
            )
          "
        >
          {{ "KH_MQHY" in infoList ? infoList.KH_MQHY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(
              2,
              'KH_MQHS',
              infoList.KH_MQHS_node_string,
              'KH_MQHS_BM',
              '自定义吨蒸汽煤耗'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_MQHS,
              'KH_MQHS',
              infoList.KH_MQHS_node_string,
              'KH_MQHS_BM'
            )
          "
        >
          {{ "KH_MQHS" in infoList ? infoList.KH_MQHS : 0 }}
        </div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">风机电耗(kw)</div>
        <div
          class="c2 fl-t"
          @click="
            toCompon(
              2,
              'KH_DHB',
              infoList.KH_DHB_node_string,
              'KH_DHB_BM',
              '电耗班累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_DHB,
              'KH_DHB',
              infoList.KH_DHB_node_string,
              'KH_DHB_BM'
            )
          "
        >
          {{ "KH_DHB" in infoList ? infoList.KH_DHB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="
            toCompon(
              2,
              'KH_DHT',
              infoList.KH_DHT_node_string,
              'KH_DHT_BM',
              '电耗日累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_DHT,
              'KH_DHT',
              infoList.KH_DHT_node_string,
              'KH_DHT_BM'
            )
          "
        >
          {{ "KH_DHT" in infoList ? infoList.KH_DHT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="
            toCompon(
              2,
              'KH_DHY',
              infoList.KH_DHY_node_string,
              'KH_DHY_BM',
              '电耗月累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_DHY,
              'KH_DHY',
              infoList.KH_DHY_node_string,
              'KH_DHY_BM'
            )
          "
        >
          {{ "KH_DHY" in infoList ? infoList.KH_DHY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(
              2,
              'KH_DHS',
              infoList.KH_DHS_node_string,
              'KH_DHS_BM',
              '电耗自定义累计'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_DHS,
              'KH_DHS',
              infoList.KH_DHS_node_string,
              'KH_DHS_BM'
            )
          "
        >
          {{ "KH_DHS" in infoList ? infoList.KH_DHS : 0 }}
        </div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">自控率(%)</div>
        <div
          class="c2 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZKLB',
              infoList.KH_ZKLB_node_string,
              'KH_ZKLB_BM',
              '班自控率'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZKLB,
              'KH_ZKLB',
              infoList.KH_ZKLB_node_string,
              'KH_ZKLB_BM'
            )
          "
        >
          {{ "KH_ZKLB" in infoList ? infoList.KH_ZKLB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZKLT',
              infoList.KH_ZKLT_node_string,
              'KH_ZKLT_BM',
              '天自控率'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZKLT,
              'KH_ZKLT',
              infoList.KH_ZKLT_node_string,
              'KH_ZKLT_BM'
            )
          "
        >
          {{ "KH_ZKLT" in infoList ? infoList.KH_ZKLT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="toCompon(2, 'KH_ZKLY', infoList.KH_ZKLY_node_string, 'KH_ZKLY_BM', '月自控率')"
          @dblclick="Cclick(infoList.KH_ZKLY, 'KH_ZKLY', infoList.KH_ZKLY_node_string, 'KH_ZKLY_BM')"
        >
          {{ "KH_ZKLY" in infoList ? infoList.KH_ZKLY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(
              2,
              'KH_ZKLS',
              infoList.KH_ZKLS_node_string,
              'KH_ZKLS_BM',
              '自定义考核自控率'
            )
          "
          @dblclick="
            Cclick(
              infoList.KH_ZKLS,
              'KH_ZKLS',
              infoList.KH_ZKLS_node_string,
              'KH_ZKLS_BM'
            )
          "
        >
          {{ "KH_ZKLS" in infoList ? infoList.KH_ZKLS : 0 }}
        </div>
      </div>
      <div class="tdrow">
        <div class="c1 fl-t">稳定性(%)</div>
        <div
          class="c2 fl-t"
          @click="toCompon(2, 'KH_WDXB', infoList.KH_WDXB_node_string, 'KH_WDXB_BM', '班稳定性')"
          @dblclick="
            Cclick(infoList.KH_WDXB, 'KH_WDXB', infoList.KH_WDXB_node_string, 'KH_WDXB_BM')
          "
        >
          {{ "KH_WDXB" in infoList ? infoList.KH_WDXB : 0 }}
        </div>
        <div
          class="c3 fl-t"
          @click="toCompon(2, 'KH_WDXT', infoList.KH_WDXT_node_string, 'KH_WDXT_BM', '天稳定性')"
          @dblclick="
            Cclick(infoList.KH_WDXT, 'KH_WDXT', infoList.KH_WDXT_node_string, 'KH_WDXT_BM')
          "
        >
          {{ "KH_WDXT" in infoList ? infoList.KH_WDXT : 0 }}
        </div>
        <div
          class="c4 fl-t"
          @click="toCompon(2, 'KH_WDXY', infoList.KH_WDXY_node_string, 'KH_WDXY_BM', '月稳定性')"
          @dblclick="
            Cclick(infoList.KH_WDXY, 'KH_WDXY', infoList.KH_WDXY_node_string, 'KH_WDXY_BM')
          "
        >
          {{ "KH_WDXY" in infoList ? infoList.KH_WDXY : 0 }}
        </div>
        <div
          class="c5 fl-t"
          @click="
            toCompon(2, 'KH_WDXS', infoList.KH_WDXS_node_string, 'KH_WDXS_BM', '自定义考核稳定性')
          "
          @dblclick="
            Cclick(infoList.KH_WDXS, 'KH_WDXS', infoList.KH_WDXS_node_string, 'KH_WDXS_BM')
          "
        >
          {{ "KH_WDXS" in infoList ? infoList.KH_WDXS : 0 }}
        </div>
      </div>
      <div
        class="status_style"
        :style="{ background: infoList ? '' : '' }"
        @click="toCompon(5)"
      >
        数据采集
      </div>
    </div>
    <div class="fl-t middle">
      <div class="middle-up">
        <div class="button-row">
          <div
            class="status_style red fl-t"
            :style="{ background: infoList.JNKHKS ? '#000' : 'red' }"
          >
            {{
              "" in infoList
                ? infoList.JNKHKS == 1
                  ? "正在考核"
                  : "等待考核 "
                : "等待考核 "
            }}
          </div>
          <div
            class="status_style fl-t"
            @click="toIpt(infoList.JNKHKS, '考核状态', 'JNKHKS', infoList.JNKHKS_node_string)"
          >
            {{
              "" in infoList
                ? infoList.JNKHKS == 1
                  ? "结束考核"
                  : "开始考核"
                : "开始考核"
            }}
          </div>
          <!-- {{infoList.G_KHTJ.EN9?'统计使能':'统计切除':0 }} -->
        </div>
        <div class="row">
          <div class="col1 fl-t">考核时间设定</div>
          <div class="col1 fl-t">考核开始时间</div>
        </div>
        <div class="row">
          <div
            class="col2 fl-t"
            @click="toIpt(infoList.KH_SJ, '考核时间设定', 'KH_SJ', infoList.KH_SJ_node_string)"
          >
            {{ "KH_SJ" in infoList ? infoList.KH_SJ : 0 }}
          </div>
          <div class="col2 fl-t">00:00</div>
        </div>
        <div class="row">
          <div class="col1 fl-t">考核进行时间</div>
          <div class="col1 fl-t">考核结束时间</div>
        </div>
        <div class="row">
          <div class="col2 fl-t">00:00</div>
          <div class="col2 fl-t">00:00</div>
        </div>
      </div>
      <div class="zbjd">
        <div class="row">指标名称和精度</div>
        <div class="row1">
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="
                  toIpt(infoList.EN1, '汽泡液位控制精度', 'EN1', infoList.EN1_node_string)
                "
              >
                <el-switch
                  v-model="infoList.EN1"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_SWH,
                    '汽泡液位控制精度',
                    'KH_SWH',
                    infoList.KH_SWH_node_string
                  )
                "
              >
                {{ "KH_SWH" in infoList ? infoList.KH_SWH : 0 }}
              </div>
            </div>
            <div class="cname">汽泡液位控制精度</div>
          </div>
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="
                  toIpt(infoList.EN2, '主汽温度控制精度', 'EN2', infoList.EN2_node_string)
                "
              >
                <el-switch
                  v-model="infoList.EN2"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_QWH,
                    '主汽温度控制精度',
                    'KH_QWH',
                    infoList.KH_QWH_node_string
                  )
                "
              >
                {{ "KH_QWH" in infoList ? infoList.KH_QWH : 0 }}
              </div>
            </div>
            <div class="cname">主汽温度控制精度</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="
                  toIpt(infoList.EN3, '一次风压控制精度', 'EN2', infoList.EN3_node_string)
                "
              >
                <el-switch
                  v-model="infoList.EN3"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_SWL,
                    '一次风压控制精度',
                    'KH_SWL',
                    infoList.KH_SWL_node_string
                  )
                "
              >
                {{ "KH_SWL" in infoList ? infoList.KH_SWL : 0 }}
              </div>
            </div>
            <div class="cname">一次风压控制精度</div>
          </div>
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
              >
                <!-- <el-switch  v-model="infoList.EN4"                 
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch> -->
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_QWL,
                    '屏过温度控制精度',
                    'KH_QWL',
                    infoList.KH_QWL_node_string
                  )
                "
              >
                {{ "KH_QWL" in infoList ? infoList.KH_QWL : 0 }}
              </div>
            </div>
            <div class="cname">屏过温度控制精度</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="
                  toIpt(infoList.EN5, '烟气氧量控制精度', 'EN5', infoList.EN5_node_string)
                "
              >
                <el-switch
                  v-model="infoList.EN5"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_O2H,
                    '烟气氧量控制精度',
                    'KH_O2H',
                    infoList.KH_O2H_node_string
                  )
                "
              >
                {{ "KH_O2H" in infoList ? infoList.KH_O2H : 0 }}
              </div>
            </div>
            <div class="cname">烟气氧量控制精度</div>
          </div>
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
              >
                <!-- <el-switch  v-model="infoList.EN6"                 
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch> -->
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(infoList.KH_O2L, '风量控制精度', 'KH_O2L', infoList.KH_O2L_node_string)
                "
              >
                {{ "KH_O2L" in infoList ? infoList.KH_O2L : 0 }}
              </div>
            </div>
            <div class="cname">风量控制精度</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="
                  toIpt(infoList.EN7, '主汽压力控制精度', 'EN7', infoList.EN7_node_string)
                "
              >
                <el-switch
                  v-model="infoList.EN7"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_QYH,
                    '主汽压力控制精度',
                    'KH_QYH',
                    infoList.KH_QYH_node_string
                  )
                "
              >
                {{ "KH_QYH" in infoList ? infoList.KH_QYH : 0 }}
              </div>
            </div>
            <div class="cname">主汽压力控制精度</div>
          </div>
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
              >
                <!-- <el-switch  v-model="infoList.EN8"
                
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch> -->
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_QYL,
                    '主汽流量控制精度',
                    'KH_QYL',
                    infoList.KH_QYL_node_string
                  )
                "
              >
                {{ "KH_QYL" in infoList ? infoList.KH_QYL : 0 }}
              </div>
            </div>
            <div class="cname">主汽流量控制精度</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="
                  toIpt(infoList.EN9, '炉膛负压控制精度', 'EN9', infoList.EN9_node_string)
                "
              >
                <el-switch
                  v-model="infoList.EN9"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(
                    infoList.KH_FYH,
                    '炉膛负压控制精度',
                    'KH_FYH',
                    infoList.KH_FYH_node_string
                  )
                "
              >
                {{ "KH_FYH" in infoList ? infoList.KH_FYH : 0 }}
              </div>
            </div>
            <div class="cname">炉膛负压控制精度</div>
          </div>
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="toIpt(infoList.EN10, '脱硝控制精度', 'EN10', infoList.EN10_node_string)"
              >
                <el-switch
                  v-model="infoList.EN10"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(infoList.KH_FYL, '脱硝控制精度', 'KH_FYL', infoList.KH_FYL_node_string)
                "
              >
                {{ "KH_FYL" in infoList ? infoList.KH_FYL : 0 }}
              </div>
            </div>
            <div class="cname">脱硝控制精度</div>
          </div>
        </div>
        <div class="row1">
          <div class="col1 fl-t">
            <div>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="toIpt(infoList.EN11, '脱硫控制精度', 'EN11', infoList.EN11_node_string)"
              >
                <el-switch
                  v-model="infoList.EN11"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
              <div
                class="fl-t"
                style="
                  width: 3vw;
                  height: 3vh;
                  padding-left: 2vw;
                  color: #00ffb4;
                  font-size: 1.2vw;
                "
                @click="
                  toIpt(infoList.KH_FYL, '脱硫控制精度', 'KH_FYL', infoList.KH_FYL_node_string)
                "
              >
                {{ "KH_FYL" in infoList ? infoList.KH_FYL : 0 }}
              </div>
            </div>
            <div class="cname">脱硫控制精度</div>
          </div>
          <!-- <div class="col1 fl-t">
              <div>
                  <div class="fl-t "  style="width:3vw;height:3vh;margin-left:2vw;"
                   @click="
                  toIpt(
                    infoList.EN11,
                    '脱硫控制精度',
                     'EN11',
                    infoList._node_string,
                   
                  )
                ">
               <el-switch  v-model="infoList.EN11"
                
                  active-color="#29DFAE"
                                 inactive-color="red"
                  disabled>
                </el-switch>
                  </div>
              <div class="fl-t " style="width:3vw;height:3vh;padding-left:2vw;color:#00ffb4;font-size:1.2vw;"
                   @click="
                  toIpt(
                    infoList.KH_TLJD,
                    '脱硫控制精度',
                     'KH_TLJD',
                    infoList._node_string,
                   
                  )
                ">{{ "" in infoList ?infoList.KH_TLJD:0 }}
                </div>
              </div>
              <div class="cname">脱硫控制精度</div>
             
            </div> -->
          <div class="col2 fl-t">
            <div>
              <span class="ct"> 制粉系统 </span>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="toIpt(infoList.EN6, ' 制粉系统', 'EN6', infoList.EN6_node_string)"
              >
                <el-switch
                  v-model="infoList.EN6"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
            </div>
            <div>
              <span class="ct"> 周 界 风 </span>
              <div
                class="fl-t"
                style="width: 3vw; height: 3vh; margin-left: 2vw"
                @click="toIpt(infoList.EN9, '周 界 风', 'EN9', infoList.EN9_node_string)"
              >
                <el-switch
                  v-model="infoList.EN9"
                  active-color="#29DFAE"
                  inactive-color="red"
                  disabled
                >
                </el-switch>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zzkl fl-t">
      <div class="row" style="margin-top: 1vh">总自控率%</div>
      <div class="zs1"></div>
      <div>
        <span class="ck">主汽温度自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_QWZKL', infoList.KH_QWZKL_node_string, 'KH_QWZKL_BM', '汽温自控率')"
          @dblclick="
            Cclick(infoList.KH_QWZKL, 'KH_QWZKL', infoList.KH_QWZKL_node_string, 'KH_QWZKL_BM')
          "
          >{{ "KH_QWZKL" in infoList ? infoList.KH_QWZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">汽包水位自控率 </span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_GSZKL', infoList.KH_GSZKL_node_string, 'KH_GSZKL_BM', '给水自控率')"
          @dblclick="
            Cclick(infoList.KH_GSZKL, 'KH_GSZKL', infoList.KH_GSZKL_node_string, 'KH_GSZKL_BM')
          "
          >{{ "KH_GSZKL" in infoList ? infoList.KH_GSZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">锅炉负荷自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_GFZKL', infoList.KH_GFZKL_node_string, 'KH_GFZKL_BM', '给煤自控率')"
          @dblclick="
            Cclick(infoList.KH_GFZKL, 'KH_GFZKL', infoList.KH_GFZKL_node_string, 'KH_GFZKL_BM')
          "
          >{{ "KH_GFZKL" in infoList ? infoList.KH_GFZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">炉膛负压自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_FYZKL', infoList.KH_FYZKL_node_string, 'KH_FYZKL_BM', '负压自控率')"
          @dblclick="
            Cclick(infoList.KH_FYZKL, 'KH_FYZKL', infoList.KH_FYZKL_node_string, 'KH_FYZKL_BM')
          "
          >{{ "KH_FYZKL" in infoList ? infoList.KH_FYZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">一次风压自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_YFZKL', infoList.KH_YFZKL_node_string, 'KH_YFZKL_BM', '一次风自控率')"
          @dblclick="
            Cclick(infoList.KH_YFZKL, 'KH_YFZKL', infoList.KH_YFZKL_node_string, 'KH_YFZKL_BM')
          "
          >{{ "KH_YFZKL" in infoList ? infoList.KH_YFZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">烟气氧量自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_SFZKL', infoList.KH_SFZKL_node_string, 'KH_SFZKL_BM', '送风自控率')"
          @dblclick="
            Cclick(infoList.KH_SFZKL, 'KH_SFZKL', infoList.KH_SFZKL_node_string, 'KH_SFZKL_BM')
          "
          >{{ "KH_SFZKL" in infoList ? infoList.KH_SFZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">制粉系统自控率</span>
        <span
          class="cv"
          @click="
            toCompon(2, 'KH_ZFZKL', infoList.KH_ZFZKL_node_string, 'KH_ZFZKL_BM', '制粉系统自控率')
          "
          @dblclick="
            Cclick(infoList.KH_ZFZKL, 'KH_ZFZKL', infoList.KH_ZFZKL_node_string, 'KH_ZFZKL_BM')
          "
          >{{ "KH_ZFZKL" in infoList ? infoList.KH_ZFZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">脱硝自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_TXZKL', infoList.KH_TXZKL_node_string, 'KH_NOXZKL_BM')"
          @dblclick="
            Cclick(infoList.KH_TXZKL, 'KH_TXZKL', infoList.KH_TXZKL_node_string, 'KH_NOXZKL_BM')
          "
          >{{ "KH_TXZKL" in infoList ? infoList.KH_TXZKL : 0 }}
        </span>
      </div>
      <div>
        <span class="ck">脱硫自控率</span>
        <span
          class="cv"
          @click="toCompon(2, 'KH_TLZKL', infoList.KH_TLZKL_node_string, 'KH_SO2ZKL_BM')"
          @dblclick="
            Cclick(infoList.KH_TLZKL, 'KH_TLZKL', infoList.KH_TLZKL_node_string, 'KH_SO2ZKL_BM')
          "
          >{{ "KH_TLZKL" in infoList ? infoList.KH_TLZKL : 0 }}
        </span>
      </div>
      <div class="zs2">
        <div
          class="zs2-1"
          @click="toCompon(2, 'KH_ZZKL', infoList.KH_ZZKL_node_string, 'KH_ZZKL_BM', '总自控率')"
          @dblclick="
            Cclick(infoList.KH_ZZKL, 'KH_ZZKL', infoList.KH_ZZKL_node_string, 'KH_ZZKL_BM')
          "
        >
          {{ "KH_ZZKL" in infoList ? infoList.KH_ZZKL : 0 }}
        </div>
        <div class="zs2-2">总自控率</div>
      </div>
    </div>
    <div class="bwdzb fl-t">
      <div class="row">班稳定性指标</div>
      <div class="zs1"></div>
      <div class="zs2">
        <div
          class="zs21"
          @click="toCompon(2, 'KH_WDXB', infoList.KH_WDXB_node_string, 'KH_WDXB_BM', '班稳定性')"
          @dblclick="
            Cclick(infoList.KH_WDXB, 'KH_WDXB', infoList.KH_WDXB_node_string, 'KH_WDXB_BM')
          "
        >
          {{ "KH_WDXB" in infoList ? infoList.KH_WDXB : 0 }}
        </div>
        <div class="zs22">稳定性指标</div>
      </div>
    </div>
    <div class="rsxl fl-t">
      <div class="row">燃烧效率统计</div>

      <div class="zs1">
        <div
          class="zs11"
          @click="toCompon(2, 'RSXLSS', infoList.RSXLSS_node_string, 'RSXLSS_BM', '燃烧效率')"
          @dblclick="Cclick(infoList.RSXLSS, 'KH_ZZKL', infoList.RSXLSS_node_string, 'RSXLSS_BM')"
        >
          {{ "RSXLSS" in infoList ? infoList.RSXLSS : 0 }}
        </div>
        <div class="zs12">效率统计</div>
      </div>
      <div class="zs2">
        <span class="zs21">
          {{'KH_ZZKL' in infoList?
            infoList.KH_ZZKL >= 90
              ? "A"
              : infoList.KH_ZZKL < 90 && infoList.KH_ZZKL >= 70
              ? "B"
              : infoList.KH_ZZKL < 70 && infoList.KH_ZZKL >= 60
              ? "C"
              : "D": "D"
          }}
        </span>
        <span class="zs21">
          {{'KH_WDXB' in infoList?
            infoList.KH_WDXB >= 90
              ? "A"
              : infoList.KH_WDXB < 90 && infoList.KH_WDXB >= 70
              ? "B"
              : infoList.KH_WDXB < 70 && infoList.KH_WDXB >= 60
              ? "C"
              : "D": "D"
          }}
        </span>
        <span class="zs21">
          {{'RSXLSS' in infoList?
            infoList.RSXLSS >= 90
              ? "A"
              : infoList.RSXLSS < 90 && infoList.RSXLSS >= 70
              ? "B"
              : infoList.RSXLSS < 70 && infoList.RSXLSS >= 60
              ? "C"
              : "D": "D"
          }}
        </span>
      </div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isHshow = false"
      :historyname="historyname"
      :node="node"
      :node1="node1"
      :Lkname="Lkname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Firstcontrol
      :infoList="infoList"
      :isIndex="isIndex"
      :historyname="Firstcontrolname"
      :titname="Firstcontroltitname"
      :node="Firstcontrolnode"
      v-if="isFshow"
      @sendStatus="isFshow = false"
    >
    </Firstcontrol>
    <Rsf
      v-if="isRshow"
      @sendStatus="isRshow = false"
      :titname="Rsftitname"
      :infoList="infoList"
      :historyname="Rsfname"
      :node="Rsfnode"
    ></Rsf>
    <ParameterYh
      v-if="isComShow"
      :infoList="infoList"
      @sendStatus="isshowfase"
    ></ParameterYh>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isMshow = false"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    >
    </Manual>
    <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    <MFSJCJ v-if="isSJshow" @sendStatus="isSJshow = false" :infoList="infoList">
    </MFSJCJ>
  </div>
</template>



<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import MFSJCJ from "@/views/MfBoiler/MFSJCJ/index.vue"; //输入框组件
import Historical from "@/components/Historicaljrl.vue"; //历史趋势
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Rsf from "@/components/RSF.vue"; //阮伺服组件
import ParameterYh from "@/components/ParameterYh.vue"; //参数YH组件
import Manual from "@/components/Manual1.vue"; //手操器组件
export default {
  name: "MflSecurity",
  props: {
    infoList: {
      type: Object,
      default: {}, // 默认值
    },
  },
  components: {
    inputVal,
    Historical,
    Firstcontrol,
    Rsf,
    ParameterYh,
    Manual,
    MFSJCJ,
  },
  data: () => {
    return {
      chName: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      isHshow: false,
      isMshow: false,
      isRshow: false,
      isSJshow: false,
      historyname: "",
      node: "",
      Lkname: "",
      isIndex: "",
      Firstcontrolname: "",
      Firstcontroltitname: "",
      Firstcontrolnode: "",
      isFshow: false,
      Rsfname: "",
      Rsfnode: "",
      Rsftitname: "",
      projectData: "",
      grouptime: null,
      spotArr: [],
      authInfo: [],
      UserInfo: [],
      node1:''
    };
  },
  created() {
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }
        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    // 打开先控多窗口
    toDetail(key, name, nodename, Aname, titname) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无调试权限");
        }
      } else {
        let pathname = "";
        switch (key) {
          case 1:
            pathname = "firstcontolJRL";
            break;
          case 2:
            pathname = "mfkfirstcontol";
            break;
          case 3:
            pathname = "mfRfirstcontol";
            break;
        }
        const newurl = this.$router.resolve({
          name: pathname,
          query: {
            Firstcontrolname: name,
            Firstcontrolnode: nodename,
            Firstcontroltitname: titname,
          },
        });
        window.open(newurl.href, "_blank");
      }
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isshowfase() {
      this.isComShow = false;
    },
    closeCompon() {
      this.$emit("sendStatus", "JRLParam02", false);
    },
    isClose(val, val1) {
      switch (val) {
        case "Historical":
          return (this.isHshow = val1);
      }
    },
    DataJson(data) {
      console.log(data);
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if (!this.authInfo[1]) {
        let str = this.authInfo[0];
        // 判断是否为调试还是查看权限
        // console.log(str.charAt(str.length - 1));
        if (str.charAt(str.length - 1) == "r") {
          this.$message.error("暂无操作权限");
        }
      } else {
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      clearTimeout(this.grouptime);
      this.grouptime = setTimeout(() => {
        switch (key) {
          case 0:
            return (
              (this.isMshow = true),
              (this.Manualname = name),
              (this.Manualnode = name2),
              (this.ManualAname = name3),
              (this.Manualtitname = titname)
            );
          case 1:
            return (
              (this.isFshow = true),
              (this.Firstcontrolname = name),
              (this.Firstcontrolnode = name2),
              (this.Firstcontroltitname = titname)
            );
          case 2:
            return (this.isHshow = true), (this.chName = name4),this.node1=titname;
          case 4:
            return (
              (this.isRshow = true),
              (this.Rsfname = name),
              (this.Rsfnode = name2),
              (this.Rsftitname = titname)
            );
          case 5:
            return (this.isSJshow = true);
        }
      }, 300);
    },
  },
};
</script>


<style lang="scss" scoped>
.fl-t {
  float: left;
}

.fr-t {
  float: right;
}

::v-deep {
  .el-switch.is-disabled .el-switch__core {
    cursor: pointer !important;
  }

  .el-switch.is-disabled {
    opacity: 1 !important;
  }
}

.title-hang {
  height: 5vh;
  width: 96vw;
  font-family: MicrosoftYaHei;
  font-size: 2vh;
  font-weight: normal;
  font-stretch: normal;
  line-height: 2vh;
  letter-spacing: 0vh;
}

.zhuangshizb {
  width: 14vw;
  height: 2vh;
  background-image: url("~@/assets/images/rfl_evaluate06.png");
  background-size: 100% 100%;
  margin-left: 3.5vw;
  margin-top: 1.5vh;
}

.zhuangshizj {
  width: 70vw;
  height: 4vh;
  line-height: 4vh;
  margin-top: 1vh;
  text-align: center;
  // background-image: url("~@/assets/images/rfl_evaluate05.png");
  background-size: 100% 100%;
  // margin-left: 5vw;
  font-family: MicrosoftYaHei;
  font-size: 3vh;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0vh;
  color: #0ef7ff;
}

.zhuangshiyb {
  width: 5vw;
  height: 2vh;
  // background-image: url("~@/assets/images/rfl_evaluate04.png");
  background-size: 100% 100%;
  // margin-left: 5vw;
  margin-top: 1.5vh;
}

.sjxs {
  width: 33vw;
  height: 79vh;
  margin-left: 1.5vw;
  background-image: url("~@/assets/images/运行考核_底图01.png");
  background-size: 100% 100%;
  margin-top: 1vh;

  .row {
    padding-left: 3vw;
    width: 100%;
    height: 4vh;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #ffffff;
    font-family: PingFang-SC-Regular;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
  }

  .throw {
    width: 100%;
    height: 3vh;
    font-family: PingFang-SC-Regular;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 3vh;
    letter-spacing: 0vh;
    color: #458ca4;
    padding: 2px;
    padding-left: 1vw;

    .fl-t {
      width: 6vw;
      text-align: center;
    }

    .c1 {
      width: 7vw;
    }

    .c5 {
      width: 6vw;
      cursor: pointer;
    }
  }

  .tdrow {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    font-family: PingFang-SC-Regular;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vh;
    color: #00e4ff;
    padding: 2px;
    padding-left: 1vw;
    align-content: center;
    cursor: pointer;

    .fl-t {
      width: 6vw;
      text-align: center;
    }

    .c1 {
      width: 7vw;
      align-content: center;
      color: #8aeaff;
    }

    .c5 {
      width: 5vw;
    }
  }

  .status_style {
    margin-left: 0.5vw;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 4vh;
    letter-spacing: 0vw;
    margin-top: 14vh;
    margin-left: 12vw;
    width: 7.5vw;
    height: 4vh;
    text-align: center;
    background-color: #00ff8a;
    border-radius: 0vh;
    border: solid 0vh #00fc98;
    cursor: pointer;
  }

  .red {
    border: 1px solid #ff0d1e;
    color: #ff0d1e;
  }
}

.middle {
  margin: 1vh 1vw;
  margin-bottom: 5px;
  width: 30vw;
  height: 79vh;
}

.zbjd {
  width: 30vw;
  height: 60vh;
  background-image: url("~@/assets/images/运行考核_底图03.png");
  background-size: 100% 100%;
  margin-top: 1.5vh;

  .row {
    padding-left: 2vw;
    width: 100%;
    height: 4vh;
    line-height: 4vh;
    letter-spacing: 0vh;
    color: #ffffff;
    font-family: PingFang-SC-Regular;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
  }

  .row1 {
    width: 30vw;
    height: 6vh;
    padding: 1vh 1vw;

    .col1 {
      width: 10vw;
      height: 5.5vh;
      background-image: url("~@/assets/images/运行考核-指标.png");
      background-size: 100% 100%;
      margin-top: 1vh;
      margin-left: 2vw;
      padding: 1vh 0.5vw;

      .cv {
        margin-top: -4px;
        font-family: PingFang-SC-Regular;
        font-size: 19px;
        font-weight: normal;
        font-stretch: normal;

        letter-spacing: 0vw;
        color: #00ffb4;
        float: right;
        cursor: pointer;
      }

      .cname {
        width: 10vw;
        height: 4vh;
        text-align: center;
        font-family: PingFang-SC-Regular;
        font-size: 1.8vh;
        font-weight: normal;
        font-stretch: normal;
        line-height: 4vh;
        letter-spacing: 0vw;
        color: #8aeaff;
      }
    }

    .col2 {
      width: 10vw;
      height: 5.5vh;
      margin-top: 1vh;
      margin-left: 2vw;
      padding: 1vh 0.5vw;
      cursor: pointer;

      .ct {
        width: 3vw;
        font-family: PingFang-SC-Regular;
        font-size: 1vw;
        font-weight: normal;
        font-stretch: normal;
        line-height: 3vh;
        letter-spacing: 0vw;
        color: #ffffff;
      }
    }
  }
}

.middle-up {
  width: 30vw;
  height: 16vh;
  padding-top: 1vh;
  background-image: url("~@/assets/images/jrl-evaluate1.png");
  background-size: 100% 100%;

  .button-row {
    width: 30vw;
    height: 3vh;
    margin: 0vh 1vw;
    margin-bottom: 5px;

    .status_style {
      margin-left: 0.5vw;
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 3vh;
      letter-spacing: 0vw;

      width: 7.5vw;
      height: 3vh;
      text-align: center;
      background-color: #0085eb;
      border-radius: 0vh;
      border: solid 0vh #00fc98;
      margin: 0vh 3.2vw;
    }

    .red {
      border: 1px solid #ff0d1e;
      background-color: #ff0d1e;
    }
  }

  .row {
    width: 30vw;
    height: 3vh;
    margin: 0vh 1vw;

    .col1 {
      width: 6vw;
      margin-left: 0.5vw;
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 3vh;
      letter-spacing: 0vw;
      color: #458ca4;
      margin: 1px 4vw;
    }

    .col2 {
      width: 6vw;
      margin-left: 0.5vw;
      font-family: PingFang-SC-Regular;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vh;
      letter-spacing: 0vw;
      color: #ffffff;
      margin: 1px 4vw;
      text-align: center;
      cursor: pointer;
    }

    .red {
      border: 1px solid #ff0d1e;
      background-color: #ff0d1e;
    }
  }
}

.zzkl {
  width: 30vw;
  height: 36vh;
  background-image: url("~@/assets/images/运行考核_底图04.png");
  background-size: 100% 100%;
  margin-top: 1.5vh;

  .row {
    width: 30vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5vh;
    letter-spacing: 0vw;
    color: #ffffff;
    padding-left: 2.5vw;
    margin-bottom: 1vh;
  }

  .ck {
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vw;
    color: #8aeaff;
    margin-left: 1vw;
  }

  .cv {
    margin-left: 3vw;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vw;
    color: #00e4ff;
    cursor: pointer;
    margin-top: 3vh;
  }

  .zs1 {
    float: right;
    width: 6vw;
    height: 12vh;
    background-image: url("~@/assets/images/jrl-evaluate9.png");
    background-size: 100% 100%;
    margin-top: 1.5vh;
    margin-right: 10vh;
  }

  .zs2 {
    float: right;
    width: 9vw;
    height: 12vh;
    background-image: url("~@/assets/images/jrl-evaluate8.png");
    background-size: 100% 100%;
    margin-top: -14vh;
    margin-right: 7vh;

    .zs2-1 {
      font-family: PingFang-SC-Regular;
      font-size: 2vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vw;
      letter-spacing: 0vw;
      color: #00e4ff;
      margin-top: 2vh;
      margin-left: 3vw;
      cursor: pointer;
    }

    .zs2-2 {
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vw;
      letter-spacing: 0vw;
      color: #8aeaff;
      margin-top: 1vh;
      margin-left: 2.4vw;
    }
  }
}

.bwdzb {
  width: 30vw;
  height: 18vh;
  background-image: url("~@/assets/images/运行考核_底图05.png");
  background-size: 100% 100%;
  margin-top: 1.5vh;

  .row {
    width: 30vw;
    height: 1.5vw;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5vw;
    letter-spacing: 0vw;
    color: #ffffff;
    padding-left: 1.8vw;
    margin-bottom: 1vh;
  }

  .zs1 {
    float: left;
    width: 6vw;
    height: 12vh;
    margin-top: 0.5vh;
    margin-left: 6vw;
    background-image: url("~@/assets/images/MFL_班稳定性切图.png");
    background-size: 100% 100%;
  }

  .zs2 {
    float: left;
    width: 6vw;
    height: 12vh;
    margin-top: 0.5vh;
    margin-left: 4vw;

    .zs21 {
      width: 4vw;
      height: 2vw;
      font-family: PingFang-SC-Regular;
      font-size: 2vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1vw;
      letter-spacing: 0vw;
      color: #00e4ff;
      margin-top: 2.5vh;
      cursor: pointer;
    }

    .zs22 {
      width: 5vw;
      height: 1vw;
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1vw;
      letter-spacing: 0vw;
      color: #288aab;
    }
  }
}

.rsxl {
  width: 30vw;
  height: 18vh;
  background-image: url("~@/assets/images/运行考核_底图06.png");
  background-size: 100% 100%;
  margin-top: 1.5vh;

  .row {
    width: 30vw;
    height: 1.5vw;
    font-family: PingFang-SC-Regular;
    font-size: 1vw;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.5vw;
    letter-spacing: 0vw;
    color: #ffffff;
    padding-left: 1.8vw;
    margin-bottom: 1vh;
  }

  .zs1 {
    float: left;
    width: 7vw;
    height: 12vh;
    margin-top: 0.5vh;
    margin-left: 6vw;
    background-image: url("~@/assets/images/MFL_效率统计.png");
    background-size: 100% 100%;

    .zs11 {
      margin-top: 1vh;
      width: 7vw;
      height: 3vh;
      font-family: PingFang-SC-Regular;
      font-size: 1.8vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 3vh;
      letter-spacing: 0vw;
      color: #00e4ff;
      text-align: center;
      cursor: pointer;
    }

    .zs12 {
      width: 7vw;
      height: 2vh;
      font-family: PingFang-SC-Regular;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 2vw;
      letter-spacing: 0vw;
      color: #288aab;
      text-align: center;
    }
  }

  .zs2 {
    float: left;
    width: 8vw;
    height: 6vh;
    margin-top: 2.5vh;
    margin-left: 6vw;
    padding-left: 0.8vw;
    background-image: url("~@/assets/images/MFL_性能总指标.png");
    background-size: 100% 100%;

    .zs21 {
      width: 6vw;
      height: 2vh;
      font-family: PingFang-SC-Regular;
      font-size: 2vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 6vh;
      letter-spacing: 0vw;
      color: #8aeaff;
      margin-right: 0.9vw;
      // background-color: #fff;
      box-sizing: border-box;
    }
  }
}
</style>
